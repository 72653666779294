<template>
  <div class="order-monthly-received">
    <WaterEnergyTitle title="维修接单人月度接单数" />
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
export default {
  name: "orderMonthlyReceived",
  components: {
    WaterEnergyTitle,
  },
};
</script>

<style lang="less" scoped>
.order-monthly-received {
  height: 270px;
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
}
</style>