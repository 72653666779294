<template>
  <div class="order-reading-overview">
    <WaterEnergyTitle
      title="各维修工单类别的阅读概况（共 4 类维修类型，本月共 171 单维修工单，上月共 181 单维修工单）"
    />
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
export default {
  name: "orderReadingOverview",
  components: {
    WaterEnergyTitle,
  },
};
</script>

<style lang="less" scoped>
.order-reading-overview {
  height: 290px;
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
}
</style>