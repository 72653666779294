<template>
  <div class="order-completion-rate">
    <WaterEnergyTitle title="各维修工单类型的完成率情况" />
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
export default {
  name: "orderCompletionRate",
  components: {
    WaterEnergyTitle,
  },
};
</script>

<style lang="less" scoped>
.order-completion-rate {
  height: 270px;
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
}
</style>