<template>
  <div class="app-container inspection-visualization-component">
    <div class="search-line">
      <WaterEnergySearch type="rwork" />
    </div>
    <div class="card-container">
      <el-row :gutter="30">
        <el-col :span="8">
          <!-- 各维修工单类型的完成率情况 -->
          <WorkOrderCompletionRate />
        </el-col>
        <el-col :span="16">
          <!-- 月度每日的生产量/完成量趋势图 -->
          <WorkOrderCompletionTrend />
        </el-col>
      </el-row>
    </div>
    <div class="card-container">
      <!-- 各维修工单类别的阅读概况（共 4 类维修类型，本月共 171 单维修工单，上月共 181 单维修工单） -->
      <WorkOrderReadingOverview />
    </div>
    <div class="card-container">
      <el-row :gutter="30">
        <el-col :span="16">
          <!-- 维修接单人月度接单数 -->
          <WorkOrderMonthlyReceived />
        </el-col>
        <el-col :span="8">
          <!-- 各维修工单类型的平均接单/处理时长(分) -->
          <WorkOrderAvgProcessingDuration />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import WaterEnergySearch from "@/components/WaterEnergySearch";
import WorkOrderCompletionRate from "./components/workOrderCompletionRate.vue";
import WorkOrderCompletionTrend from "./components/workOrderCompletionTrend.vue";
import WorkOrderReadingOverview from "./components/workOrderReadingOverview.vue";
import WorkOrderMonthlyReceived from "./components/workOrderMonthlyReceived.vue";
import WorkOrderAvgProcessingDuration from "./components/workOrderAvgProcessingDuration.vue";
export default {
  name: "inspectionVisualizationComponent",
  components: {
    WaterEnergySearch,
    WorkOrderCompletionRate,
    WorkOrderCompletionTrend,
    WorkOrderReadingOverview,
    WorkOrderMonthlyReceived,
    WorkOrderAvgProcessingDuration,
  },
};
</script>

<style lang="less" scoped>
.inspection-visualization-component {
  margin: 10px 20px 20px 40px;
  background: none;
  padding-left: 0;
  .card-container {
    margin-top: 20px;
  }
}
</style>