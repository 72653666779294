var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container inspection-visualization-component" },
    [
      _c(
        "div",
        { staticClass: "search-line" },
        [_c("WaterEnergySearch", { attrs: { type: "rwork" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "card-container" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 30 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [_c("WorkOrderCompletionRate")],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [_c("WorkOrderCompletionTrend")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card-container" },
        [_c("WorkOrderReadingOverview")],
        1
      ),
      _c(
        "div",
        { staticClass: "card-container" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 30 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [_c("WorkOrderMonthlyReceived")],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [_c("WorkOrderAvgProcessingDuration")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }