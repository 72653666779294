<template>
  <div class="order-processing-duration">
    <WaterEnergyTitle title="各维修工单类型的平均接单/处理时长(分)" />
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
export default {
  name: "orderProcessingDuration",
  components: {
    WaterEnergyTitle,
  },
};
</script>

<style lang="less" scoped>
.order-processing-duration {
  height: 270px;
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
}
</style>